import { ChangeDetectorRef, Component, DoCheck, EventEmitter, Input, Output } from '@angular/core';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import { OrderListAction } from '../../enums/OrderListAction';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import { DisplayIdentifier } from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import Article from '../../../smoothr-web-app-core/models/Article';
import { ValidationUtils } from '../../../smoothr-web-app-core/utils/validation-utils';
import Order from '../../../smoothr-web-app-core/models/Order';
import { getBasePrice, isDepositArticle, numberD, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import Venue from 'src/smoothr-web-app-core/models/Venue';

@Component({
	selector: 'app-order-list',
	templateUrl: './order-list.component.html',
	styleUrls: ['order-list.component.scss'],
})
export class OrderListComponent implements DoCheck {
	@Output() action = new EventEmitter<OrderListAction>();
	@Input() editable = true;
	@Input() isVerifiedArticle = false;
	@Input() venue: Venue;
	OrderUtils = OrderUtils;
	isSomeOptionFullPrice = false;
	needsConfiguration = false;
	articleGroupPrice = 0;
	articlePrice = 0;
	numberToCurrency = numberToCurrency;
	private prevPromoState = false;
	private prevQuantity = 1;
	numberD = numberD;
	constructor(private cd: ChangeDetectorRef) {}

	private _articleGroup: ArticleGroup;

	get articleGroup(): ArticleGroup {
		return this._articleGroup;
	}

	@Input()
	set articleGroup(value: ArticleGroup) {
		this._articleGroup = value;
		this.reloadArticle();
	}

	private _order: Order;

	get order(): Order {
		return this._order;
	}

	@Input()
	set order(value: Order) {
		this._order = value;
		this.reloadArticle();
	}

	edit() {
		if (!this.isEditable()) {
			return;
		}
		this.action.emit(OrderListAction.edit);
	}

	isEditable(): boolean {
		return this.editable;
	}

	delete() {
		if (!this.isEditable()) {
			return;
		}
		this.action.emit(OrderListAction.delete);
	}

	async note() {
		if (!this.editable) {
			return;
		}
		this.action.emit(OrderListAction.note);
	}

	add() {
		if (!this.isEditable()) {
			return;
		}
		this.action.emit(OrderListAction.add);
	}

	remove() {
		if (!this.isEditable()) {
			return;
		}
		this.action.emit(OrderListAction.remove);
	}

	isOptionFullPrice(articleOption: ArticleOption): boolean {
		const group = this.articleGroup.article.groups.find(grp => grp._id === articleOption.group);
		return group && group.displayIdentifiers && group.displayIdentifiers.findIndex(di => DisplayIdentifier.fullPrice === di) >= 0;
	}

	hiddenInCart(article: Article): boolean {
		return (
			(article.tags && article.tags.find(tag => tag.identifier === 'hide_cart') !== undefined) || article?.optionNumber === '99999999'
		);
	}
	hiddenSanifairOption(article: Article): boolean {
		return article.tags && article.tags.find(tag => tag.identifier === 'sanifair_voucher') != undefined;
	}

	reloadArticle() {
		this.needsConfiguration = false;
		this.isSomeOptionFullPrice = false;
		this.articleGroupPrice = 0;
		this.prevPromoState = false;
		this.prevQuantity = 1;
		if (!this.articleGroup || !this.order || !this.order.preorder) {
			this.articleGroupPrice = 0;
			return;
		}
		// this.prevQuantity = this.articleGroup.quantity;
		// this.prevPromoState = this.articleGroup.isPromo;
		// this.needsConfiguration = !ValidationUtils.areGroupsValid(this.articleGroup, this.articleGroup.article.groups);
		// for (const articleOption of this.articleGroup.groups) {
		// 	if (this.isOptionFullPrice(articleOption)) {
		// 		this.isSomeOptionFullPrice = true;
		// 	}
		// }
		// this.defaultPrice(this.articleGroup.article);
		// const copy = JSON.parse(JSON.stringify(this.articleGroup)) as ArticleGroup;
		// copy.quantity = 1;
		// this.articlePrice = OrderUtils.totalPrice(copy, this.order.type, this.order.preorder.type);
		if (this.articleGroup?.totalPrice && numberD(this.articleGroup?.totalPrice) < 0) {
			this.articleGroupPrice = numberD(this.articleGroup?.totalPrice);
			return;
		}
		this.articleGroupPrice = OrderUtils.totalPrice(this.articleGroup, this.order.type, this.order.preorder.type);
		if (OrderUtils.isBogoOrFreeArticlePromo(this.order) && this.articleGroup.isPromo) {
			this.articleGroupPrice -= OrderUtils.bogoPrice(this.articleGroup, this.order.type, this.order.preorder.type);
		}
	}
	defaultPrice(article: Article) {
		if (this.isVerifiedArticle && this.venue) {
			const art = this.venue.articleCategories.map(cat => cat.articles.find(art => art._id === article._id))[0];
			const articleGroup = new ArticleGroup();
			articleGroup.article = art;
			articleGroup.groups = [];
			articleGroup.quantity = 1;
			this.articlePrice = OrderUtils.articleGroupsTotalPrice([articleGroup], OrderType.PREORDER, this.order?.preorder?.type);
			return;
		}
		const articleGroup = new ArticleGroup();
		articleGroup.article = article;
		articleGroup.groups = [];
		articleGroup.quantity = 1;
		this.articlePrice = OrderUtils.articleGroupsTotalPrice([articleGroup], OrderType.PREORDER, this.order?.preorder?.type);
	}
	ngDoCheck(): void {
		if (this.prevPromoState !== this.articleGroup.isPromo) {
			this.reloadArticle();
			this.cd.markForCheck();
		}
		if (this.prevQuantity !== this.articleGroup.quantity) {
			this.reloadArticle();
			this.cd.markForCheck();
		}
	}
	checkArticleTagEmpty(event: ArticleOption) {
		if (event.article.tags?.length > 0) {
			return event.article.tags.some(it => it.identifier === 'empty') && !numberD(event?.article?.price);
		} else {
			return false;
		}
	}

	showArticle(article: Article): boolean {
		if (
			article &&
			isDepositArticle(article) &&
			article.tags.find(it => it.identifier === 'deposit15' || it.identifier === 'deposit8' || it.identifier === 'deposit25')
		) {
			return false;
		}
		return true;
	}
	checkSanifairTag(article: Article) {
		return article?.tags?.find(tag => tag?.identifier?.includes('sanifair_voucher'));
	}
	checkPfandTag(article: Article) {
		if (article.tags?.length === 0) {
			return '';
		}
		const deposit25 = article.tags.find(it => it.identifier === 'deposit25') ? 'deposit25' : '';
		const deposit15 = article.tags.find(it => it.identifier === 'deposit15') ? 'deposit15' : '';

		return deposit15 || deposit25;
	}
	checkMeasurmentPlusPfand(article: Article) {
		let result = '';

		if (article?.measurement && article?.measurement?.unit && article?.measurement?.amount) {
			result =
				result +
				' ' +
				article.measurement?.refAmount +
				' ' +
				article.measurement?.refUnit +
				' = ' +
				this.numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.order?.preorder?.type), this.order?.currency);
		}
		return result;
	}
}
