import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import Tag from '../../../smoothr-web-app-core/models/Tag';

@Component({
	selector: 'app-tag',
	templateUrl: './tag.component.html',
	styleUrls: ['tag.component.scss'],
})
export class TagComponent implements OnInit {
	public filteredTags: Tag[] = [];
	@Input() set tags(value: Tag[]) {
		this.filteredTags = value.filter(it => it.identifier != 'is_main_article');
	}
	environment = environment;

	constructor() {}

	ngOnInit() {}
}
