import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ShortInfoModalComponent } from '../short-info-modal/short-info-modal.component';
import { RecommendationModalAction, RecommendationModalComponent } from '../recommendation-modal/recommendation-modal.component';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import { getAvailability, getPrice, numberD, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { filterMatchingOptions, OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { ValidationUtils } from '../../../smoothr-web-app-core/utils/validation-utils';
import { DisplayMode } from '../../../smoothr-web-app-core/enums/DisplayMode';
import { DisplayIdentifier } from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import FulfilledDependency from '../../../smoothr-web-app-core/models/FulfilledDependency';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { defaultsToArticleOption, sleep, getBasePrice } from '../../../smoothr-web-app-core/utils/utils';
import { environment } from 'src/environments/environment';
import { PromoCodeType } from '../../../smoothr-web-app-core/models/PromoCodeType';
import { AnalyticsService } from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import { Nutrition } from 'src/smoothr-web-app-core/models/Nutrition';

@Component({
	selector: 'app-modal-info',
	templateUrl: './modal-info.component.html',
	styleUrls: ['modal-info.component.scss'],
})
export class ModalInfoComponent extends RepositoryDirective implements OnInit, AfterViewInit {
	environment = environment;
	articleGroup: ArticleGroup;
	// false if coming from checkout
	recommend = true;
	OrderUtils = OrderUtils;
	validationUtils = ValidationUtils;
	// @ViewChild(IonSlides, { static: true }) slides: IonSlides;
	@ViewChild('swiperInfo') slides: ElementRef | undefined;

	dm = DisplayMode;
	di = DisplayIdentifier;
	ot = OrderType;
	slideOpts = {
		spaceBetween: 0,
		initialSlide: 0,
		speed: 400,
		allowTouchMove: false,
	};
	sliding = false;
	pages: OptionGroup[][] = [];
	currentPageIndex = 0;
	indicesOfRecommendations: number[] = [];
	isLastPage = false;
	numberToCurrency = numberToCurrency;
	getPrice = getPrice;
	getBasePrice = getBasePrice;
	filterMatchingOptions = filterMatchingOptions;
	showOnlyRequiredGroups: boolean;
	@ViewChild('main_col', { read: ElementRef }) main_col: ElementRef;

	constructor(
		public modalCtrl: ModalController,
		protected repository: RepositoryService,
		private snackBarCtrl: MatSnackBar,
		private translate: TranslateService,
		private analytics: AnalyticsService,
		private cdr: ChangeDetectorRef
	) {
		super(repository);
	}

	get type(): PreorderType {
		return this.order.preorder.type;
	}

	ngOnInit() {
		super.ngOnInit();
		console.log('showOnlyRequiredGroups', this.showOnlyRequiredGroups);
		this.analytics.viewItem(this.articleGroup, this.order);
		this.analytics.beginArticleEdit(this.articleGroup.article);
		for (const group of this.articleGroup.article.groups) {
			const index = Math.floor(group.sortOrder / 10);
			if (this.pages[index] === undefined) {
				this.pages[index] = [];
			}
			this.pages[index].push(group);
		}
		if (this.pages.length === 0) {
			this.pages.push([]);
		}
		if (this.pages.length > 1) {
			this.pages = this.pages.filter(page => page !== null);
		}

		if (
			this.pages.length >= 1 &&
			this.pages[0].length === 1 &&
			(this.pages[0][0].displayMode === DisplayMode.DETAIL_OVERLAY || this.pages[0][0].sortOrder > 9)
		) {
			this.pages = [[], ...this.pages];
		}
		this.pages = this.pages.filter(it => !!it && !!it[0]);
		this.indicesOfRecommendations = [];
		console.log(this.pages);
		this.pages.forEach((page, pageIndex) => {
			if (page.length > 0 && this.articleGroup.article.recommendations.find(recommendation => recommendation.group === page[0]._id)) {
				this.indicesOfRecommendations.push(pageIndex);
			}
		});
		if (this.showOnlyRequiredGroups) {
			this.pages = this.pages.filter(it => {
				return it.filter(it => it.requiredAmount == 0).length === 0;
			});
		}

		this.onSlideChange();
	}

	async ngAfterViewInit() {
		console.log('showOnlyRequiredGroups', this.showOnlyRequiredGroups);

		await this.slides?.nativeElement.swiper.isBeginning;
	}

	async finish() {
		this.analytics.finishArticleEdit(this.articleGroup.article, this.currentPageIndex);
		await this.modalCtrl.dismiss({
			articleGroup: this.articleGroup,
		});
	}

	async close() {
		this.analytics.cancelArticleEdit(this.articleGroup.article, this.currentPageIndex);
		await this.modalCtrl.dismiss();
	}

	increase() {
		this.articleGroup.quantity += 1;
		console.log(this.articleGroup);
	}

	decrease() {
		if (this.articleGroup.quantity > 1) {
			this.articleGroup.quantity -= 1;
		}
	}

	async addOverlayOption(pageIndex: number, optionIndex: number, optionArticle: Article, group: OptionGroup, dependencyNumber: number) {
		const option = new ArticleOption();
		option.article = optionArticle;
		option.quantity = 1;
		option.group = group._id;
		const fulfilledDependency = new FulfilledDependency();
		fulfilledDependency.dependency = null;
		fulfilledDependency.dependsOn = null;
		fulfilledDependency.times = 0;
		await this.addAndNext(pageIndex, optionIndex, option, group, fulfilledDependency, dependencyNumber);
	}

	async addAndNext(
		pageIndex: number,
		optionGroupIndex: number,
		option: ArticleOption,
		group: OptionGroup,
		fulfilledDependency: FulfilledDependency,
		dependencyNumber: number
	) {
		await this.add(pageIndex, optionGroupIndex, option, group, fulfilledDependency, dependencyNumber);
		this.cdr.detectChanges();
		await this.slides?.nativeElement?.swiper.update;

		await sleep(100);
		await this.nextSlide();
	}

	optionGroupDisabled(optionGroup: OptionGroup): boolean {
		if (OrderUtils.isBogoOrFreeArticlePromo(this.order) && this.order.promoCode.type === PromoCodeType.BOGO) {
			const bogoArticleIndex = this.order.orderedArticles.findIndex(ag => ag.isPromo);
			const bogoArticle = this.order.orderedArticles[bogoArticleIndex];
			const matchingBogoArticleIndices = this.order.orderedArticles
				.map((ag, agIndex) => {
					return ag.article._id === bogoArticle.article._id &&
						!ag.isPromo &&
						OrderUtils.bogoPrice(ag, this.order.type, this.order.preorder.type) ===
							OrderUtils.bogoPrice(bogoArticle, this.order.type, this.order.preorder.type)
						? agIndex
						: -1;
				})
				.filter(agIndex => agIndex !== -1);
			// article that should be removed is a possible parent of promoCode article
			if (
				matchingBogoArticleIndices.length === 1 &&
				bogoArticle.article._id === this.articleGroup.article._id &&
				OrderUtils.bogoPrice(this.articleGroup, this.order.type, this.order.preorder.type) ===
					OrderUtils.bogoPrice(bogoArticle, this.order.type, this.order.preorder.type)
			) {
				return !this.recommend;
			}
		}
		return this.articleGroup.isPromo && optionGroup.displayIdentifiers.indexOf(DisplayIdentifier.main) >= 0;
	}
	async add(
		pageIndex: number,
		optionGroupIndex: number,
		option: ArticleOption,
		group: OptionGroup,
		fulfilledDependency: FulfilledDependency,
		dependencyNumber: number
	) {
		if (this.sliding) {
			return;
		}
		console.log(option.article.name.de, dependencyNumber);
		OrderUtils.addOption(this.articleGroup.groups, option, group, fulfilledDependency, dependencyNumber);
		// get all fulfilled but not valid options
		// check if !hasMultiple && limit == requiredAmount == size
		// add all options with dependency
		this.articleGroup.groups = this.articleGroup.groups.filter(group => {
			const foundOption = this.articleGroup.article.groups.find(it => it._id === group.group);
			if (!foundOption) {
				return false;
			}
			const dependencies = ValidationUtils.isGroupDependencyFulfilled(
				this.articleGroup.article,
				this.articleGroup.groups,
				foundOption
			);
			if ((dependencies.times > 0 && group.dependencyNumber === 0) || group.dependencyNumber > dependencies.times) {
				return false;
			}
			return true;
		});
		this.articleGroup.article.groups.forEach(grp => {
			if (ValidationUtils.areGroupsValid(this.articleGroup, [grp])) {
				return;
			}
			if (grp.limit != grp.requiredAmount || grp.limit != grp.articles.length || grp.hasMultiple) {
				return;
			}

			const dependency = ValidationUtils.isGroupDependencyFulfilled(this.articleGroup.article, this.articleGroup.groups, grp);
			console.log(dependency);
			for (let depNumber = 1; depNumber <= dependency.times; depNumber++) {
				console.log('For Each', depNumber);

				grp.articles.forEach(opt => {
					const option = new ArticleOption();
					option.article = opt;
					option.group = grp._id;
					option.dependency = dependency?.dependency?._id;
					option.dependencyNumber = depNumber;
					option.dependsOn = dependency?.dependsOn;
					option.quantity = 1;
					OrderUtils.addOption(this.articleGroup.groups, option, grp, dependency, depNumber);
				});
			}
		});
		console.log(
			this.articleGroup.article.name.de,
			this.articleGroup.groups.map(option => ({
				name: option.article.name,
				dependencyNumber: option.dependencyNumber,
				quantity: option.quantity,
			}))
		);

		if (
			this.pages[pageIndex].length <= optionGroupIndex ||
			group.limit <= 0 ||
			this.articleGroup.groups
				.map(addedOption => (group._id === addedOption.group ? addedOption.quantity : 0))
				.reduce((prev, curr) => prev + curr, 0) < group.limit
		) {
			return;
		}

		const slideIndex = await this.slides?.nativeElement.swiper.activeIndex;
		try {
			const container =
				// @ts-ignore
				this.slides.el.children[0].children[slideIndex].children[0].children[1];
			const options: HTMLCollection = container.children;
			const previousNotFulfilledOptions = this.pages[pageIndex]
				.map(
					(optionGroup, index) =>
						index < optionGroupIndex &&
						ValidationUtils.isGroupDependencyFulfilled(this.articleGroup.article, this.articleGroup.groups, optionGroup).times <
							0
				)
				.filter(res => res).length;
			// if (
			// 	options.length - 1 <
			// 	optionGroupIndex + 2 - previousNotFulfilledOptions
			// ) {
			// 	this.nextSlide();
			// 	return;
			// }
			options[optionGroupIndex + 2 - previousNotFulfilledOptions].scrollIntoView({ behavior: 'smooth' });
		} catch (e) {
			console.error({
				pageIndex,
				message: 'could not scroll',
				e,
			});
		}
	}

	async pageIndex(): Promise<number> {
		await sleep(100);
		let activeIndex = this.slides?.nativeElement.swiper.activeIndex;

		const activeIndexValue = activeIndex + this.hiddenCount(activeIndex);
		return activeIndexValue;
		// let activeIndex = await this.slides.getActiveIndex();
		// activeIndex = activeIndex + this.hiddenCount(activeIndex);
		// return activeIndex;
		return 0;
	}

	hiddenCount(until: number): number {
		let realIndex = until;
		this.indicesOfRecommendations.forEach(index => {
			if (realIndex >= index) {
				realIndex = realIndex + 1;
			}
		});
		return this.articleGroup.isRecommendedRecipe
			? this.indicesOfRecommendations
					.map(index => (index <= realIndex ? 1 : 0))
					.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
			: 0;
	}

	async addToOrder() {
		if (
			this.currentPageIndex === 0 &&
			this.recommend &&
			this.articleGroup.article.recommendations &&
			this.articleGroup.article.recommendations.length > 0 &&
			ValidationUtils.areGroupsValid(this.articleGroup, this.pages[0])
		) {
			const recommendations = defaultsToArticleOption(
				this.articleGroup.article,
				this.articleGroup.groups,
				this.articleGroup.article.recommendations,
				OrderType.PREORDER,
				this.order.preorder.type
			);
			const groups: OptionGroup[] = [];
			this.articleGroup.article.recommendations.forEach(option => {
				const insertedGrp = groups.find(grp => grp._id === option.group);
				if (!insertedGrp) {
					const group = this.articleGroup.article.groups.find(grp => option.group === grp._id);
					// only fulfilled recommendations are relevant
					if (ValidationUtils.isGroupDependencyFulfilled(this.articleGroup.article, recommendations, group).times >= 0) {
						groups.push(this.articleGroup.article.groups.find(grp => option.group === grp._id));
					}
				}
			});
			// Check if some recommendations are inactive before we allow the user to select recommendations
			const allRequiredAvailable = groups
				.map(grp => recommendations.filter(opt => opt.group === grp._id).length)
				.map((value, index) => groups[index].requiredAmount <= value)
				.reduce((previousValue, currentValue) => previousValue && currentValue, true);
			const recommendationAction = allRequiredAvailable
				? await RecommendationModalComponent.show(this.modalCtrl)
				: RecommendationModalAction.CUSTOMIZATION;
			switch (recommendationAction) {
				case RecommendationModalAction.CUSTOMIZATION:
					this.articleGroup.isRecommendedRecipe = false;
					await sleep(100);
					if (!(await this.slides?.nativeElement.swiper.isEnd)) {
						await this.nextSlide();

						return;
					}
					if (!ValidationUtils.areGroupsValid(this.articleGroup, this.articleGroup.article.groups)) {
						this.snackBarCtrl.open(this.translate.instant('modal_info.pick_error'), null, {
							duration: 4000,
							panelClass: ['red-snackbar'],
						});
						return;
					}
					await this.finish();
					return;
				case RecommendationModalAction.DISMISS:
					return;
				case RecommendationModalAction.RECOMMENDATION:
					this.articleGroup.isRecommendedRecipe = true;
					await sleep(100);
					// filter options of first page
					const optionsToKeep = [
						...this.articleGroup.groups.filter(
							option => this.pages[0].findIndex(optionGroup => optionGroup._id === option.group) >= 0
						),
						...recommendations,
					];
					this.articleGroup.groups = [];
					optionsToKeep.forEach(opt => {
						const grp = this.articleGroup.article.groups.find(group => group._id === opt.group);
						const dep = ValidationUtils.isGroupDependencyFulfilled(this.articleGroup.article, optionsToKeep, grp);
						OrderUtils.addOption(this.articleGroup.groups, opt, grp, dep, opt.dependencyNumber);
					});
					await this.nextSlide();
					return;
				default:
					return;
			}
		} else {
			if (this.articleGroup.isRecommendedRecipe) {
				for (const recommendationIndex of this.indicesOfRecommendations) {
					const page = this.pages[recommendationIndex].map(group => group._id);
					this.articleGroup.groups = this.articleGroup.groups.filter(opt => page.indexOf(opt.group) < 0);
				}
				this.articleGroup.groups = defaultsToArticleOption(
					this.articleGroup.article,
					this.articleGroup.groups,
					this.articleGroup.article.recommendations,
					OrderType.PREORDER,
					this.order.preorder.type
				);
			}

			console.log(
				await this.slides.nativeElement.swiper,
				await this.slides?.nativeElement?.swiper?.isEnd,
				!(await this.slides?.nativeElement?.swiper?.isEnd)
			);
			await this.slides.nativeElement?.swiper.update();
			if (!(await this.slides?.nativeElement?.swiper?.isEnd)) {
				console.log('NEXT SLIDE');
				await this.nextSlide();
				return;
			}

			console.log(this.articleGroup.groups, this.articleGroup.article.groups);
			if (!ValidationUtils.areGroupsValid(this.articleGroup, this.articleGroup.article.groups)) {
				this.snackBarCtrl.open(this.translate.instant('modal_info.pick_error'), null, {
					duration: 4000,
					panelClass: ['red-snackbar'],
				});
				return;
			}

			await this.finish();
		}
	}
	findInvalidGroup(articleGroup: ArticleGroup, optionGroups: OptionGroup[]): OptionGroup | null {
		let foundInvalidgroup = null;
		for (const optionGroup of optionGroups) {
			const dependency = ValidationUtils.isGroupDependencyFulfilled(articleGroup.article, articleGroup.groups, optionGroup);

			if (!dependency || dependency.times < 0) {
				articleGroup.groups = articleGroup.groups.filter(option => option.group !== optionGroup._id);
				continue;
			}
			const matchingOptions = filterMatchingOptions(articleGroup.groups, optionGroup, dependency, 0);
			const count = matchingOptions.reduce((prev, next) => prev + next.quantity, 0);
			if (optionGroup.requiredAmount > count || (optionGroup.limit < count && optionGroup.limit !== 0)) {
				console.log(optionGroup);
				console.log('count', count);
				console.log('matchingOptions', matchingOptions);

				console.log({
					name: optionGroup.name.de,
					min: optionGroup.requiredAmount,
					max: optionGroup.limit,
					dependency,
					matchingOptions: matchingOptions.map(opt => [opt.quantity, opt.article.name.de]),
					allOptions: articleGroup.groups.map(opt => opt.article.name.de),
				});
				foundInvalidgroup = optionGroup;
				return foundInvalidgroup;
			}
		}
		return foundInvalidgroup;
	}
	async onSlideChange() {
		const pageIndex = await this.pageIndex();
		const slideIndex = pageIndex;
		console.log(pageIndex);
		this.currentPageIndex = pageIndex;

		this.isLastPage = await this.slides?.nativeElement.swiper.isEnd;

		console.log({
			// slideIndex,
			index: pageIndex,
			lastPage: this.isLastPage,
			pages: this.pages.map(page => page.map(grp => grp.name.de)),
			modes: this.pages.map(page => page.map(grp => grp.displayMode)),
		});
	}

	async prevSlide() {
		if (this.sliding) {
			return;
		}
		if (await this.slides?.nativeElement?.swiper?.isBeginning) {
			this.close();
		}
		this.sliding = true;
		// await this.slides.update();

		if (!(await this.slides?.nativeElement?.swiper?.isBeginning)) {
			await this.slides?.nativeElement?.swiper.slidePrev(500);
		}
		this.sliding = false;
	}

	async nextSlide() {
		if (this.sliding) {
			return;
		}

		this.main_col.nativeElement.scrollTop = 0;
		this.sliding = true;
		if (await this.slides?.nativeElement.swiper.isEnd) {
			await this.addToOrder();
			return;
		}
		const validateVisibleGroups = this.pages.filter((it, index) => this.pageVisible(index));
		await this.validateAndSlideNext(validateVisibleGroups[await this.pageIndex()]);
	}

	async validateAndSlideNext(optionGroups: OptionGroup[]) {
		const pageValid = ValidationUtils.areGroupsValid(this.articleGroup, optionGroups);
		if (!pageValid) {
			const result = this.findInvalidGroup(this.articleGroup, this.articleGroup.article.groups);
			console.log('ARE VALID', result);
			if (result) {
				const length = this.articleGroup.groups.filter(it => it?.group === result?._id).length + 1;
				const element = document.getElementById(result._id + length);
				console.log('ARE VALID', element);

				if (element) {
					element.scrollIntoView({ behavior: 'smooth', block: 'start' });
				}
				try {
					// await InfoModalComponent.show(this.modalCtrl, {
					// 	title: this.translate.instant('modal_info.pick_error'),
					// 	info: result.description[this.translate.currentLang] ?? '',
					// 	closeButton: this.translate.instant('short_info.close'),
					// });
				} catch (e) {}
			} else {
				this.snackBarCtrl.open(this.translate.instant('modal_info.pick_error'), null, {
					duration: 2000,
				});
			}
			this.snackBarCtrl.open(this.translate.instant('modal_info.pick_error'));
			this.sliding = false;
			return;
		}
		if (await this.slides?.nativeElement?.swiper.isEnd) {
			await this.addToOrder();
		} else {
			this.analytics.stepArticleEdit(this.articleGroup.article, this.currentPageIndex);
			// await this.slides.update();
			// await this.slides.slideNext();
			await this.slides.nativeElement?.swiper.update();

			await this.slides?.nativeElement?.swiper.slideNext(500);
		}
		this.sliding = false;
	}

	async closeSlide() {
		console.log(this.sliding);
		if (this.sliding) {
			return;
		}
		this.sliding = true;
		await this.slides.nativeElement?.swiper.update();
		await this.slides.nativeElement?.swiper.slideTo(0);
		this.sliding = false;
	}

	async openInfo(article: Article) {
		console.log(article.info);
		await ShortInfoModalComponent.show(this.modalCtrl, article);
	}

	currentPage(): OptionGroup {
		return this.pages[this.currentPageIndex][0];
	}

	nextFulfilledPage(currentPage: number): number {
		return this.pages.findIndex(
			(groups, index) => index > currentPage && ValidationUtils.someGroupsFulfilled(this.articleGroup, groups)
		);
	}

	previousFulfilledPage(currentPage: number): number {
		return Math.max(
			...this.pages.map((page, index) => {
				if (index < currentPage && ValidationUtils.someGroupsFulfilled(this.articleGroup, page)) {
					return index;
				} else {
					return 0;
				}
			})
		);
	}

	async clearCurrentPageAndNext(pageIndex) {
		this.articleGroup.groups = this.articleGroup.groups.filter(option => {
			for (const grp of this.pages[pageIndex]) {
				if (option.group === grp._id) {
					return false;
				}
			}
			return true;
		});
		await this.nextSlide();
	}

	async requireOneOfAndNextSlide(pageIndex) {
		const currentPageArticles = this.articleGroup.groups.filter(option => {
			for (const grp of this.pages[pageIndex]) {
				if (option.group === grp._id) {
					return true;
				}
			}
			return false;
		});
		if (currentPageArticles.length === 0) {
			this.snackBarCtrl.open(this.translate.instant('modal_info.pick_error'));
			return;
		}
		await this.nextSlide();
	}

	pageVisible(pageIndex: number): boolean {
		// Some pages needs to be invisible (if dependencies are not fulfilled or recommendations taken)

		return (
			(!this.articleGroup.isRecommendedRecipe || this.indicesOfRecommendations.indexOf(pageIndex) < 0) &&
			ValidationUtils.someGroupsFulfilled(this.articleGroup, this.pages[pageIndex])
			// (!this.articleGroup.isRecommendedRecipe || this.indicesOfRecommendations.indexOf(pageIndex) < 0) &&
			// ValidationUtils.someGroupsFulfilled(this.articleGroup, this.pages[pageIndex]) &&
			// !!this.pages[pageIndex].find(it => it.visible && !!it.articles.find(art => art.visible)) &&
			// !!this.pages[pageIndex][0]?.articles.find(it => it.visible && getAvailability(it, OrderType.PREORDER, this.order.preorder.type))

			// || !ValidationUtils.areGroupsValid(this.articleGroup, this.pages[pageIndex])
		);
	}

	isPageOverlay(pageIndex: number): boolean {
		return pageIndex !== 0 && this.pages[pageIndex][0].displayMode !== DisplayMode.DETAIL_OVERLAY;
	}
	isAllergens(article: Article) {
		if (article?.compound?.allergens) return Object.values(article?.compound?.allergens)?.find(it => it === true);
		else return null;
	}

	isAddit(article: Article) {
		if (article?.compound?.additives) return Object.values(article?.compound?.additives)?.find(it => it === true);
		else return null;
	}
	isHaveAnyValueMoreZero(article: Article) {
		return Object.values((article as Article & { nutritions: Nutrition })?.nutritions)
			.map(it => (numberD(it) ? numberD(it) : 0))
			.find(it => it > 0);
	}
	findFeature(article: Article) {
		console.log(
			Object.entries(article.compound?.features)
				.filter(it => it[0] == 'new' || it[0] == 'special' || it[0] == 'topSelling')
				.filter(it => !!it[1])
				.map(it => it[0])[0]
		);
		if (article?.compound?.features) {
			delete article.compound?.features?._id;
			delete article.compound?.features?.updatedAt;
			delete article.compound?.features?.createdAt;
			delete article.compound?.features?.createdAt;
			return Object.entries(article.compound?.features)
				.filter(it => it[0] == 'new' || it[0] == 'special' || it[0] == 'topSelling')
				.filter(it => !!it[1])
				.map(it => it[0])[0];
		}
		return '';
	}
	checkArticlesAvalability(optionGroup: OptionGroup) {
		return (optionGroup.articles ?? [])?.find(it => getAvailability(it, OrderType.PREORDER, this.order.preorder.type) && it.visible);
	}
	checkVeganFeature(article: Article) {
		return Object.entries(article.compound?.features)
			.filter(it => it[0] == 'vegan' || it[0] == 'vegetarian')
			.filter(it => !!it[1])
			.map(it => it[0])[0];
	}
	checkPfandTag(article: Article) {
		if (article.tags?.length === 0) {
			return '';
		}
		const deposit25 = article.tags.find(it => it.identifier === 'deposit25') ? 'deposit25' : '';
		const deposit15 = article.tags.find(it => it.identifier === 'deposit15') ? 'deposit15' : '';

		return deposit15 || deposit25;
	}
	checkMeasurmentPlusPfand(article: Article) {
		let result = '';
		if (!this.order || !article) {
			return;
		}
		const pfandValue = this.checkPfandTag(article);
		if (pfandValue) {
			result =
				this.translate.instant('pfand_text.zzgl') +
				(pfandValue === 'deposit15' ? numberToCurrency(0.15, this.order.currency) : numberToCurrency(0.25, this.order.currency)) +
				this.translate.instant('pfand_text.pfand') +
				'<br/>';
		} else {
			if (result.length > 0) {
				result = result + ')';
			}
		}
		if (article?.measurement && article?.measurement?.unit && article?.measurement?.amount) {
			result =
				result +
				' ' +
				article.measurement?.refAmount +
				' ' +
				article.measurement?.refUnit +
				' = ' +
				this.numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.order.preorder.type), this.order.currency);
		}
		return result;
	}
	generateDependencies(fulfilledDependency: FulfilledDependency): number[] {
		if (fulfilledDependency.times === 0) return [0];
		if (fulfilledDependency.times === -1) return [];
		const dependencyNumbers: number[] = [];
		for (let dependencyNumber = 1; dependencyNumber <= fulfilledDependency.times; dependencyNumber++) {
			dependencyNumbers.push(dependencyNumber);
		}
		return dependencyNumbers;
	}
}
